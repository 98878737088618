<template>
  <div id="contact-form" class="contact-form">
    <v-card v-if="isSending"
            id="contact-form-1"
            class="contact-form"
            elevation="2"
    >
      <!-- <div class="separator"></div> -->
      <!--<div class="pa-2">
        <v-btn
          color="pink"
          dark
          outline
          absolute
          @click="close"
        >
          <v-icon>fas fa-close</v-icon>
        </v-btn>
      </div> -->
      <div class="alert mt-3">
        <br>
        <h1> Thanks for submitting your registration.</h1>
        <br>
        <h4> Our support staff will be contacting you shortly.</h4>
        <br>
        <v-layout justify-center class="px-3 pb-1">
          <v-flex lg5
                  md5
                  sm5
                  xl5
                  xs5
                  class="px-1"
          >
            <v-btn
              block
              large
              color="error"
              class="white--text py-1"
              @click="$router.push({path:'/dashboard'})"
            >
              <div class="py-1">
                <h3 style="color:white;">
                  <b>Return to Home</b>
                </h3>
              </div>
            </v-btn>
          </v-flex>
          <v-flex lg5
                  md5
                  sm5
                  xl5
                  xs5
                  class="px-1"
          >
            <v-btn block
                   large
                   color="blue darken-3"
                   class="white--text py-1"
                   @click="close"
            >
              <div class="py-1">
                <h3 style="color:white;">
                  <b>Dismiss</b>
                </h3>
              </div>
            </v-btn>
          </v-flex>
        </v-layout>
        <br>
        <br>
      </div>
    </v-card>
    <v-card id="contact-form" class="contact-form" elevation="2">
      <h1 v-if="!isSending" class="contact-form_title">
        Registration Form
      </h1>
      <br>
      <p v-if="!isSending" class="form mx-auto px-3">
        Join us for a free coding lesson. Provide your email to register.
      </p>
      <form v-if="!isSending"
            class="form mx-auto px-3"
            @submit.prevent="onSubmit"
      >
        <!--      <h4> First name </h4>
        <input v-model="contact.firstName"
               required
               name="firstName"
               placeholder="First Name"
               type="text"
               autocomplete="off"
        >  -->
        <!--    <h4> Last name </h4>
        <input v-model="contact.lastName"
               required
               name="lastName"
               placeholder="Last Name"
               type="text"
               autocomplete="off"
        >
        </v-flex>
      </v-layout> -->
        <h4>Your email</h4>
        <input v-model="contact.email"
               required
               name="email"
               placeholder="E-mail"
               type="email"
               autocomplete="off"
        >
        <!--      <h4> Your grade level (if student) </h4>
        <input v-model="contact.subject"
               required
               name="subject"
               placeholder="Grade Level"
               type="text"
               autocomplete="off"
        > -->
        <!--    <textarea v-model="contact.message"
                  name="message"
                  rows="4"
                  placeholder="Prior Coding Experience (Yes/No)?"
        /> -->
        <button class="button" @click="onSubmit()">
          <b> Submit </b>
        </button>
        <br>
        <br>
      </form>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
export default {
  name: 'Contact',
  components: { },
	data () {
  return {
		contact: {
			firstName: ' ',
			lastName: ' ',
			email: '',
			subject:'Your registration',
			message: '...',
		         },
		isSending: false
		}
	  },
//async created () {
  created () {
      this.$store.commit('navigation/resetState');
      //this.$store.dispatch('navigation/getData');
			this.$store.commit('navigation/changeTitle', 'Registration Page');
		//	this.$store.commit('navigation/changeMenu', 'Contact Us');
			this.$store.commit('navigation/toggleshowhome', false);
			this.$store.commit('navigation/replaceContact', true);
			this.$store.commit('navigation/replaceLeftArrow', true);
		},
methods: {
  close(){
    this.isSending=false
  },
  onSubmit()
	{
	 this.axios
	           .post(process.env.VUE_APP_CONTACT+'contact', this.contact)
						 .then(response => {console.log(this.contact); this.isSending = true;})
						 .catch(error => console.error(error.toString()));
	}
 },
 metaInfo: {
	           title: 'Registration Page',
	          meta: [
              {vmid: 'description', name: 'description', content: 'Register for your free coding class'},
              {vmid: 'keywords', name: 'keywords', content: 'Contact us, Have a question?, Share your feedback'}
	                ]
	        }
}
</script>
<style lang="scss">
.body {
	background: #f1f1f1;
	font-family: 'Roboto', sans-serif;
}

.contact-form {
	font-size: 16px;
	font-family: Lato;
	max-width: 500px;
	width: 100%;
	border-radius:5px;
	height:auto;
	margin: 0 auto;
}
.contact-form .alert{
font-size:18px;
text-align:center;
text-justify:center;
}
.contact-form .separator {
	border-bottom: solid 1px #ccc;
	margin-bottom: 15px;
}
.contact-form .form {
	display: flex;
	flex-direction: column;
	font-size: 16px;
	max-width: 400px;
	border-radius:8px;
	border:none;
	text-justify:center;
}

.contact-form .namebox {
	font-size: 16px;
	max-width: 450px;
	padding-right:10px;
}

.contact-form_title {
	color: #333;
	font-size: 32px;
	text-align:center;
	padding-top:20px;
}

.contact-form_subtitle {
	color: #333;
	font-size: 18px;
	text-justify:center;
}

.contact-form input[type="email"],
.contact-form input[type="text"],
.contact-form textarea {
	border: solid 1px #e8e8e8;
	font-family: 'Roboto', sans-serif;
	padding: 10px 7px;
	margin-bottom: 15px;
	outline: none;}

.contact-form textarea {
	resize: none;
}

.contact-form input[type="email"],
.contact-form input[type="text"],
.contact-form textarea,
.contact-form .button {
	border: solid 1px #333;
	font-size: 16px;
	border-radius: 5px;
}

.contact-form .button {
	background: #da552f;
	border: solid 2px #da552f;
	color: white;
	cursor: pointer;
	padding: 10px 50px;
	text-align: center;
	text-transform: uppercase;
	font-size: 16px;
	border-radius: 5px;
	text-decoration: none;
}

.contact-form .button:hover {
	background: #ea532a;
	border: solid 2px #ea532a;
}

</style>
